import "..//../CSS/App/App.css"
// import "./App.css";
import { AuthContextProvider } from "../../AuthContext";
import GeneralRoutes from "../../routes/GeneralRoutes";

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <GeneralRoutes />
      </AuthContextProvider>
    </div>
  );
}
export default App;
