import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const SpeechCard = ({ speech }) =>  {
    return (<>
    <Card
            sx={{
              width:"100%",
              boxShadow: "none", // Remove shadow
              border: "none",
              backgroundColor: "transparent",
            }}
          >
            <CardContent>
              <Typography
                variant="body2"
                sx={{
                  fontSize: {
                    xs: "11.3px", // Font size for extra small screens
                    sm: "16px", // Font size for small screens
                    md: "16px", // Font size for medium screens
                  },
                  padding:"0rem",
                }}
              >
                  {speech} 
              </Typography>
            </CardContent>
          </Card> 
          </>
)
}
export default SpeechCard;