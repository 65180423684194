import OpenAI from "openai";
import axios from "axios";

//Access environment variables
const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
const openai = new OpenAI({
  apiKey: apiKey,
  dangerouslyAllowBrowser: true, // defaults to process.env["OPENAI_API_KEY"]
});

//Loading text
const fetchLoadingReply = async (option) => {
  try {
    const chatCompletion = await openai.chat.completions.create({
      messages: [
        {
          role: "user",
          content: `Generate a short message to enthusiastically say the chosen option 
        is exciting. Direct them to wait for the affirmation being generated.
        ###
        option: ${option}
        reply:
        `,
        },
      ],
      model: "gpt-4",
      max_tokens: 60,
    });
    return chatCompletion.choices[0].message.content;
  } catch (error) {
    console.error("API call error:", error);
    throw error; // Re-throw the error so it can be caught in the handleSubmit function.
  }
};

//API Affirmation fetch
const fetchBotReplyAsync = async (option) => {
  try {
    const chatCompletion = await openai.chat.completions.create({
      messages: [
        {
          role: "user",
          content: `Generate a dynamic, clear, enthusiastic, and emotionally powerful affirmation
                   in the present tense based on an option.
                   ###
                   option: ${option}
                   affirmation:`,
        },
      ],
      model: "gpt-4",
      max_tokens: 80,
      temperature: 0.8,
    });
    return chatCompletion.choices[0].message.content;
  } catch (error) {
    console.error("API call error:", error);
    throw error; // Re-throw the error so it can be caught in the handleSubmit function.
  }
};

//generate voice from text voice can be alloy/echo/fable/onyx/nova/shimmer
async function generateSpeech(text, voice, apiKey) {
  const url = "https://api.openai.com/v1/audio/speech";
  const headers = {
    Authorization: `Bearer ${apiKey}`,
    "Content-Type": "application/json",
  };
  const data = {
    model: "tts-1",
    input: text,
    voice: voice,
  };
  try {
    const response = await axios.post(url, data, {
      headers: headers,
      responseType: "blob",
    });
    const audioUrl = URL.createObjectURL(new Blob([response.data],{ type: 'audio/mpeg' }));
    return audioUrl
    //const audio = new Audio(audioUrl);
    //audio.play();
  } catch (error) {
    console.error("Error generating speech:", error);
    return null //Return null in case of error
  }
}
export { fetchLoadingReply, fetchBotReplyAsync, generateSpeech };
