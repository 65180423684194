import { Box, Card } from "@mui/material";
import React, { useState, useRef } from "react";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import { generateSpeech } from "../../APIFetch/FetchBotResponse/FetchBotResponse";
import CloseIcon from "@mui/icons-material/Close";
//import { v4 as uuidv4 } from "uuid";

const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
const Favorites = ({ savedAff, deleteFavorites }) => {
  const [showCard, setShowCard] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const handlePlay = async (textToSpeak, voice, apiKey) => {
    if (isPlaying) return;
    setIsPlaying(true);

    const audioUrl = await generateSpeech(textToSpeak, voice, apiKey);
    if (audioUrl) {
      audioRef.current = new Audio(audioUrl);
      audioRef.current.play();
      audioRef.current.onended = () => {
        setIsPlaying(false);
      };
    } else {
      setIsPlaying(false);
    }
  };

  const toggleShowCard = () => {
    setShowCard(!showCard);
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "0rem",
        width: "90%",
      }}
    >
      {savedAff.savedAffirmaitionsArr.length > 0 ? (
        <Card
          sx={{
            padding: "1rem",
            width: { xs: "95%", sm: "95%", md: "60%" },
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "1rem",
            boxShadow: "0 3px 4px inset grey",
            backgroundColor: "whitesmoke",
          }}
        >
          <button
            onClick={toggleShowCard}
            style={{
              backgroundColor: "#0d0d0d",
              color: "white",
              margin: "1rem",
              padding: "1.1rem",
              border: "none",
              boxShadow: "0px 4px 5px grey",
              borderRadius: "0.8rem",
            }}
          >
            {savedAff.category}
          </button>
          {showCard ? (
            <>
              {savedAff.savedAffirmaitionsArr.map((arrItem, index) => (
                <Card
                  sx={{
                    padding: "1rem",
                    width: "80%",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: "2rem",
                    boxShadow: "0 3px 5px  grey",
                    position: "relative",
                  }}
                  // key={uuidv4()}
                  key={index}
                >
                  <CardContent
                    sx={{
                      p: "4px 1.5px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{
                        fontSize: {
                          xs: "14px",
                          sm: "22px",
                          md: "18px",
                        },
                        padding: "0.9rem 0.3125rem 0 0.3125rem",
                        // textAlign: "justify",
                      }}
                    >
                      {arrItem.replace(/"/g, "")}
                    </Typography>
                  </CardContent>
                  <CardActions
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "0.25rem 0.25rem",
                    }}
                  >
                    <PlayCircleFilledIcon
                      className="playIcon"
                      disabled={isPlaying}
                      onClick={() =>
                        handlePlay(
                          `${arrItem.replace(/"/g, "")}`,
                          "nova",
                          apiKey
                        )
                      }
                    />
                    <CloseIcon
                      onClick={() => deleteFavorites(savedAff.id, arrItem)}
                      className="deleteIcon"
                      style={{}}
                      sx={{
                        position: "absolute",
                        right: 4,
                        top: 2,
                        width: { xs: "1.1rem", sm: "1.4rem", md: "1.4rem" },
                      }}
                    />
                  </CardActions>
                </Card>
              ))}
            </>
          ) : null}
        </Card>
      ) : null}
    </Box>
  );
};
export default Favorites;
