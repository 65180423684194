
import { initializeApp } from "firebase/app";
import {getAuth } from "firebase/auth";
// import firebase from "firebase/compat/app";
// Required for side-effects
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY;

const firebaseConfig = {
  apiKey:firebaseApiKey,
  authDomain: "affirmaition.firebaseapp.com",
  projectId: "affirmaition",
  storageBucket: "affirmaition.appspot.com",
  messagingSenderId: "62658630740",
  appId: "1:62658630740:web:90698cf69453fcddf689c1",
  measurementId: "G-8NSJ3J0GC4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);//can use to find who is authenticated etc
export const db = getFirestore(app) 
export default app 