import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { db } from "../../firebaseConfig";
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import Favorites from "../../components/Favorites";
import Donate from "../../components/Donate";

const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
const SavedAffirmaitions = () => {
  const [saved, setSaved] = useState([]);

  //Read saved affirmations from Firebase & save in state to be displayed in Card
  useEffect(() => {
    const q = query(collection(db, "affirmaitions")); //setup a path for our database from firebase
    //takes snapshot of our database on firebase and read it to us on screen
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let affirmaitionsArr = [];
      querySnapshot.forEach((doc) => {
        affirmaitionsArr.push({ ...doc.data(), id: doc.id });
      });
      setSaved(affirmaitionsArr);
    });
    return () => unsubscribe();
  }, []);
  //delete item in savedAffirmaitionsArr array
  const deleteFavorites = async (documentId, valueToDelete) => {
    // Fetch the document
    const docRef = doc(db, "affirmaitions", documentId);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      // If the document exists, modify the array
      const data = docSnapshot.data();
      const updatedArray = data.savedAffirmaitionsArr.filter(
        (value) => value !== valueToDelete
      );
      // Update the document with the modified array
      await updateDoc(docRef, { savedAffirmaitionsArr: updatedArray });
    } else {
      console.error("Document does not exist");
    }
  };
  return (
    <div>
      <Header />
      <h3 style={{ textShadow: "0px 0px 6px grey", marginTop: "6rem" }}>
        Favorites
      </h3>
      <p style={{ fontSize: 9, fontStyle: "italic" }}>
        {" "}
        (Disclaimer: Playback voice non-human & AI-generated, and thus has
        limitations)
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "0% 5% 1rem 5%",
          gap: "1.5rem",
        }}
      >
        {saved.map((savedAff, index) => (
          <Favorites
            key={index}
            savedAff={savedAff} //savedAff is each object within the array
            deleteFavorites={deleteFavorites}
          />
        ))}
      </div>
      <Donate />
      <Footer />
    </div>
  );
};
export default SavedAffirmaitions;
