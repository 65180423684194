import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Welcome from "../pages/Welcome";
import Main from "../pages/Home";
import SignInSide from "../pages/SignIn";
import SavedAffirmaitions from "../pages/SavedAffirmaitions";
import Protected from "../components/Protected";

const GeneralRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/signin" element={<SignInSide />} />
        <Route
          path="/main"
          element={
            <Protected>
              <Main />
            </Protected>
          }
        />
        <Route
          path="/favorites"
          element={
            <Protected>
              <SavedAffirmaitions />
            </Protected>
          }
        />
      </Routes>
    </Router>
  );
};

export default GeneralRoutes;
