import React from "react";
import { Button } from "@mui/material";
import PaymentsIcon from "@mui/icons-material/Payments";

const Donate = () => {
  const donationUrl =
    "https://www.paypal.com/donate/?business=P5HCA95SSRRN2&no_recurring=0&item_name=I%27m+a+creative+who+loves+building+%28AI%29+apps+that+have+positive+impact+on+humanity.+Your+support+is+much+appreciated.+Thank+you.&currency_code=USD";
  return (
    <div
      style={{
        marginBottom: "4rem",
        paddingBottom:"1rem"
      }}
    >
      <Button
      style={{ textDecoration: "none", padding:"0.5rem 1rem"}}
      className="donateButton"
        href={donationUrl}
        target="_blank"
        variant="text"
        color="success"
        rel="noopener noreferrer"
        endIcon={<PaymentsIcon />} 
      >
        Donate
      </Button>
    </div>
  );
};
export default Donate;
