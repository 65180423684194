import React from "react";
import { Navigate } from "react-router-dom";
import { UserAuth } from "../../AuthContext";

//can wrap around the pages we want to protect, if user not logged in.
const Protected = ({ children }) => {
  const { user } = UserAuth();
  if (!user) {
    return <Navigate to="/" />;
  }
  return children;
};

export default Protected;
