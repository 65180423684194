import React from "react";
import { Box } from "@mui/material";
import SpeechCard from "../SpeechCard";

export default function SpeechBubble({ speech, isBtnClicked, user }) {
  const userName = user?.displayName?.split(" ")[0] || "friend";
  const intro = (
    <>
      Hello {userName} ! <br />
      Pick an aspect of life you want to improve.
      <br />
      I'll give you the perfect affirmation to manifest it!
    </>
  );

  return (
    <Box
      component={"div"}
      sx={{
        width: { xs: "85%", sm: "60%", md: "60%" },
        minHeight: "124px",
        margin: "auto",
        border: "3px solid rgb(221, 255, 255)",
        boxShadow: "0px 1px 18px 3px rgb(205, 255, 255)",
        backgroundColor: "white",
        alignSelf: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isBtnClicked ? (
        <SpeechCard speech={speech} />
      ) : (
        <SpeechCard speech={intro} />
      )}
    </Box>
  );
}
