import Header from "../../components/Header";
import Footer from "../../components/Footer";
import React, { useEffect } from "react";
import BotInterface from "../../components/BotInterface";
import SimplePopUp from "../../components/SimplePopUp";
import {
  fetchLoadingReply,
  fetchBotReplyAsync,
} from "../../APIFetch/FetchBotResponse/FetchBotResponse";
import SimpleBackdrop from "../../components/Backdrop";
import { UserAuth } from "../../AuthContext";
import { useMainState } from "../../useMainState";

const Main = () => {
  const { user } = UserAuth();
  const {
    state,
    stateSetter,
    updateIsReaffirmLoading,
    increaseReaffirmCount,
    resetReaffirmCount,
    refreshAffirmationAffirmer,
    refreshSelectedValue,
    toggleDisplay,
    refreshResponse,
    handleSubmit,
    postLoadResponseArrival,
    postAffirmationArrival,
    postReAffirmationArrival,
  } = useMainState();

  const {
    selectedValue,
    apiLoadResponse,
    apiAffirmationResponse,
    isBtnClicked,
    isLoading,
    isReaffirmLoading,
    affirmationArrived,
    reaffirmClick,
  } = state;

  useEffect(() => {
    // Call refreshResponse every time the effect runs
    refreshResponse();
    const fetchData = async () => {
      //refreshResponse();
      try {
        if (selectedValue && isBtnClicked) {
          if (reaffirmClick > 0) {
            //re-start/re-affirm call
            const affirmation = await fetchBotReplyAsync(selectedValue);
            postReAffirmationArrival(affirmation);
          } else {
            // main page affirmation call
            const loadingResponse = await fetchLoadingReply(selectedValue);
            postLoadResponseArrival(loadingResponse);
            const affirmation = await fetchBotReplyAsync(selectedValue);
            postAffirmationArrival(affirmation);
          }
        }
      } catch (error) {
        console.error("API call error:", error);
      }
    };
    fetchData();
  }, [isBtnClicked, reaffirmClick, selectedValue]);

  return (
    <>
      <Header />
      <main>
        <BotInterface
          user={user}
          handleSubmit={handleSubmit}
          isBtnClicked={isBtnClicked}
          selectedValue={selectedValue}
          stateSetter={stateSetter}
        />
        {isLoading ? (
          <SimpleBackdrop />
        ) : isBtnClicked ? (
          <SimplePopUp
            increaseReaffirmCount={increaseReaffirmCount}
            isReaffirmLoading={isReaffirmLoading}
            updateIsReaffirmLoading={updateIsReaffirmLoading}
            resetReaffirmCount={resetReaffirmCount}
            refreshAffirmationAffirmer={refreshAffirmationAffirmer}
            affirmationArrived={affirmationArrived}
            selectedValue={selectedValue}
            refreshSelectedValue={refreshSelectedValue}
            answer={apiAffirmationResponse}
            isBtnClicked={isBtnClicked}
            apiLoadResponse={apiLoadResponse}
            toggleDisplay={toggleDisplay}
          />
        ) : null}
      </main>
      <Footer />
    </>
  );
};
export default Main;
