import React from "react";
//import bot from "./bot.png";
import bot from "../../assets/images/bot.png"
import ControlledOpenSelect from "../Select";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import SpeechBubble from "../SpeechBubble";

const BotInterface = ({
  user,
  handleSubmit,
  isBtnClicked,
  selectedValue,
  stateSetter,
}) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
        marginBottom: "0rem",
      }}
      sx={{
        flexGrow: 1,
        marginTop: { xs: "1.5rem", sm: "1.5rem", md: "3rem" },
        padding: {
          xs: "1.5rem 1.5rem 1.5rem 1.5rem",
          sm: "10rem 1.5rem 12rem 1.5rem",
          md: "2.5rem 1.5rem 2.5rem 1.5rem",
        },
      }}
    >
      {/* larger grid container for speech bubble, image and select box */}
      <Grid
        container
        spacing={1}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid xs={12} md={9}>
          <SpeechBubble user={user} />
        </Grid>
        <Grid
          container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "0",
            margin: "0rem",
          }}
        >
          <Grid xs={12} md={12}>
            <img
              src={bot}
              alt="affirmation-robot"
              style={{
                width: "60%",
                height: "auto",
              }}
            />
          </Grid>
          <Grid
            container
            spacing={0}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: "0",
              margin: "0rem",
            }}
          >
            <Grid xs={12} md={12}>
              <ControlledOpenSelect
                handleSubmit={handleSubmit}
                isBtnClicked={isBtnClicked}
                selectedValue={selectedValue}
                onValueChange={(newValue) =>
                  stateSetter("selectedValue", newValue)
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default BotInterface;
