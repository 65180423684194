import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import category from "../../Data/Categories";

export default function ControlledOpenSelect({
  handleSubmit,
  isBtnClicked,
  selectedValue,
  onValueChange,
}) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleInputChange = (event) => {
    onValueChange(event.target.value);
  };

  return (
    <div>
      <FormControl
        id="select-category-form"
        style={{
          alignItems: "center",
          justifyContent: "center",
          gap: "2rem",
          padding: "0rem",
          margin: "0rem",
        }}
        sx={{ m: 1, minWidth: 220, display: "flex", flexDirection: "row" }}
      >
        <InputLabel id="demo-controlled-open-select-label">Category</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={selectedValue}
          label="Category"
          disabled={isBtnClicked}
          onChange={handleInputChange}
          sx={{
            width: {
              xs: "160px",
              sm: "200px",
              md: "220px",
            },
          }}
        >
          {category.map((item) => (
            <MenuItem key={item.id} value={item.label}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <Button
          onClick={() => {
            handleSubmit();
          }}
          variant="outlined"
          sx={{
            borderRadius: "10px",
            padding: "1rem 1.5rem",
            width: {
              xs: "90px",
              sm: "80px",
              md: "90px",
            },
            fontSize: {
              xs: "13px",
              sm: "12px",
              md: "12px",
            },
          }}
        >
          AFFIRM
        </Button>
      </FormControl>
    </div>
  );
}
