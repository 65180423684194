import React, { useEffect } from "react";
//import bot2 from "../../bot2.jpg";
import bot2 from "../../assets/images/bot2.jpg";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import GoogleButton from "react-google-button";
import { UserAuth } from "../../AuthContext";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="">
        affirmAItion
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignInSide() {
  const { googleSignIn, user } = UserAuth();
  const navigate = useNavigate();
  const handleGoogleSignIn = async (e) => {
    try {
      await googleSignIn();
      //console.log("login successful" + user);
    } catch (error) {
      console.error("Google Sign-in Error:", error);
    }
  };

  useEffect(() => {
    if (user != null) {
      navigate("/main");
    }
  }, [user]);
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${bot2})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "left",
          }}
        />
        <Grid
          sx={{
            backgroundColor: "whitesmoke",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "4rem 2rem",
            }}
          >
            <GoogleButton
              onClick={handleGoogleSignIn}
              style={{ margin: "1.5rem 1rem" }}
              sx={{ mt: 10, mb: 2, borderRadius: "2rem" }}
            />
            <Copyright sx={{ mt: 2 }} />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
