import React, { useState, useEffect } from "react";
// import botInMotion from "../botInMotion.gif";
//import botInMotion from "../../botInMotion.gif"
import botInMotion from "../../assets/images/botInMotion.gif";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import SpeechBubble from "../SpeechBubble";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebaseConfig";
import CloseIcon from "@mui/icons-material/Close";

function SimpleDialog(props) {
  const [isFavorite, setIsFavorite] = useState(false);
  const [showPart1, setShowPart1] = useState(true);
  const {
    increaseReaffirmCount,
    isReaffirmLoading,
    updateIsReaffirmLoading,
    resetReaffirmCount,
    refreshAffirmationAffirmer,
    affirmationArrived,
    selectedValue,
    refreshSelectedValue,
    onClose,
    open,
    answer,
    isBtnClicked,
    apiLoadResponse,
  } = props;

  const handleClose = () => {
    onClose();
  };

  //update existing doc if the category exists, create new doc if non-existent
  const addAnswerToCategory = async (selectedValue, answer) => {
    const user = auth.currentUser; // Get the currently authenticated user
    const userUID = user.uid; // Get the UID of the user
    const categoryRef = query(
      collection(db, "affirmaitions"),
      where("category", "==", selectedValue)
    );

    const categorySnapshot = await getDocs(categoryRef);
    if (!categorySnapshot.empty) {
      // If a document with the matching category exists, add the answer to its array
      const docRef = categorySnapshot.docs[0];
      const existingData = docRef.data();
      const existingArray = existingData.savedAffirmaitionsArr || [];
      existingArray.push(answer);
      // Update the document with the new array
      await updateDoc(docRef.ref, {
        savedAffirmaitionsArr: existingArray,
        userId: userUID, // Store the user's UID in the document
      });
    } else {
      // If the selectedValue doesn't exist in the database, create a new document
      const data = {
        category: selectedValue,
        savedAffirmaitionsArr: [answer],
        userId: userUID, // Store the user's UID in the document
        // saved: false,
      };
      await addDoc(collection(db, "affirmaitions"), data);
    }
  };

  useEffect(() => {
    // Use a setTimeout to switch to PART 2 after 5 seconds
    //setShowPart1 to false if new affirmation has loaded and has a value
    if (affirmationArrived) {
      const timer = setTimeout(() => {
        setShowPart1(false);
      }, 4000);
      return () => clearTimeout(timer); // Clear the timer if the component unmounts
    }
  }, [affirmationArrived]);

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          padding: "1rem",
          background: "whitesmoke",
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        },
      }}
    >
      {showPart1 ? (
        // PART 1
        <>
          <SpeechBubble
            speech={`${apiLoadResponse}`}
            isBtnClicked={isBtnClicked}
          />
          <img
            src={botInMotion}
            style={{
              width: "80%",
              height: "auto",
            }}
            alt="animated-bot"
          />
        </>
      ) : (
        // PART 2
        <>
          <DialogTitle style={{ margin: 0, padding: 0 }} align="center">
            Affirm with Joy
            <p
              style={{
                fontSize: "0.7rem",
                fontStyle: "italic",
                textAlign: "center",
                margin: "0.0rem",
                padding: 0,
              }}
            >
              (say it like it's already happened!)
            </p>
          </DialogTitle>
          <Card
            sx={{
              padding: "1rem",
              width: "85%",
              borderRadius: "10px",
            }}
          >
            <CardContent
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body2"
                align="center"
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "22px",
                    md: "18px",
                  },
                  padding: "0rem",
                }}
              >
                {!isReaffirmLoading
                  ? answer.replace(/"/g, "")
                  : "Please wait while I reload a new affirmAItion for you ..."}
              </Typography>
            </CardContent>
            <CardActions
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "0.25rem 0.25rem",
                gap: "9rem",
              }}
            >
              <button
                onClick={() => {
                  increaseReaffirmCount();
                  isFavorite && addAnswerToCategory(selectedValue, answer);
                  updateIsReaffirmLoading(true);
                  setIsFavorite(false);
                }} //runs toggledisplay -changeisBtnClicked to true
                style={{
                  background: "transparent",
                  outline: "none",
                  border: "none",
                  fontSize: "12px",
                  disabled: { isReaffirmLoading },
                }}
              >
                {isReaffirmLoading ? null : (
                  <RestartAltIcon style={{ color: "#1BB434" }} />
                )}
              </button>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  outline: "none",
                }}
                onClick={() => {
                  setIsFavorite(!isFavorite);
                }}
                className={isFavorite ? "active" : ""}
              >
                {isFavorite ? (
                  <FavoriteIcon style={{ color: "#D73523" }} />
                ) : isReaffirmLoading ? null : (
                  <FavoriteBorderIcon style={{ color: "#D73523" }} />
                )}
              </button>
              <button
                onClick={() => {
                  refreshSelectedValue();
                  refreshAffirmationAffirmer();
                  handleClose();
                  resetReaffirmCount();
                  isFavorite && addAnswerToCategory(selectedValue, answer);
                  updateIsReaffirmLoading(false);
                  setIsFavorite(false);
                }}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  display: "flex",
                  flexDirection: "column",
                  padding: "0.3rem",
                  gap: "1rem",
                }}
              >
                <CloseIcon />
              </button>
            </CardActions>
          </Card>
        </>
      )}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

//PopUp function which returns/wraps SimpleDialog component
export default function SimplePopUp({
  increaseReaffirmCount,
  isReaffirmLoading,
  updateIsReaffirmLoading,
  resetReaffirmCount,
  refreshAffirmationAffirmer,
  affirmationArrived,
  selectedValue,
  refreshSelectedValue,
  answer,
  isBtnClicked,
  apiLoadResponse,
  toggleDisplay,
}) {
  const [open, setOpen] = useState(true);
  const handleClose = (value) => {
    setOpen(false);
    toggleDisplay(); //sets isBtnClicked back to false
  };

  return (
    <>
      <SimpleDialog
        increaseReaffirmCount={increaseReaffirmCount}
        isReaffirmLoading={isReaffirmLoading}
        updateIsReaffirmLoading={updateIsReaffirmLoading}
        resetReaffirmCount={resetReaffirmCount}
        refreshAffirmationAffirmer={refreshAffirmationAffirmer}
        affirmationArrived={affirmationArrived}
        selectedValue={selectedValue}
        refreshSelectedValue={refreshSelectedValue}
        onClose={handleClose}
        open={open}
        answer={answer}
        isBtnClicked={isBtnClicked}
        apiLoadResponse={apiLoadResponse}
      />
    </>
  );
}
