import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { UserAuth } from "../../AuthContext";

export default function Header() {
  const navigate = useNavigate();
  const { user, logOut } = UserAuth();
  const [anchorState, setAnchorState] = useState({
    anchorElUser: null,
    anchorElMenu: null,
  });
  const { anchorElUser, anchorElMenu } = anchorState;

  const userOptions = [
    { text: "Home", icon: <HomeIcon />, route: "/main" },
    { text: "Favorites", icon: <FavoriteIcon />, route: "/favorites" },
    {
      text: "Logout",
      icon: <LogoutIcon />,
      route: "/",
      onClick: () => {
        handleSignOut();
      },
    },
  ];
  const handleMenuOpen = (event) => {
    setAnchorState((prevState) => ({
      ...prevState,
      anchorElUser: event.currentTarget,
      anchorElMenu: event.currentTarget,
    }));
  };

  const handleCloseMenu = () => {
    setAnchorState((prevState) => ({
      ...prevState,
      anchorElMenu: null,
    }));
  };
  const handleCloseUserMenu = () => {
    setAnchorState((prevState) => ({
      ...prevState,
      anchorElUser: null,
    }));
  };
  const handleSignOut = async () => {
    try {
      await logOut();
    } catch (error) {
      console.error("Google Sign-Out Error:", error);
    }
  };
  return (
    <div>
      <Box
        sx={{
          backgroundColor: "#0d0d0d",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.3em",
          textShadow: "0px 0px 6px grey",
          margin: 0,
          color: "white",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          height: "10%",
          padding: { xs: "0", sm: "1%", md: "0" },
          zIndex: 10,
        }}
      >
        <p style={{ fontSize: "1.5rem" }}>
          affirm<b>AI</b>tion
        </p>
        {user ? (
          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              onClick={handleMenuOpen}
              sx={{ position: "fixed", right: "2%", top: "2.5%" }}
            >
              <MoreVertIcon
                sx={{ color: "whitesmoke", fontSize: 22, marginBottom: 1.2 }}
              />
            </IconButton>
            <Menu
              anchorEl={anchorElMenu}
              open={Boolean(anchorElMenu)}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{
                marginTop: "0.7%", // Adjust the margin to position it below the Avatar
              }}
            >
              {userOptions.map((option, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    if (option.onClick) {
                      option.onClick();
                      navigate(option.route);
                    } else {
                      navigate(option.route);
                    }
                    handleCloseUserMenu();
                  }}
                >
                  <Typography>{option.icon}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        ) : null}
      </Box>
    </div>
  );
}
