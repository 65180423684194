const category = [
  { label: "Housing & Home".trim(), id: 1 },
  { label: "Finance & Wealth".trim(), id: 2 },
  { label: "Health & Well-being".trim(), id: 3 },
  { label: "Travel & Adventure".trim(), id: 4 },
  { label: "Relationships & Love".trim(), id: 5 },
  { label: "Creativity & Expression".trim(), id: 6 },
  { label: "Career & Employment".trim(), id: 7 },
  { label: "Education & Knowledge".trim(), id: 8 },
  { label: "Spirituality & Inner Peace".trim(), id: 9 },
  { label: "Personal Growth & Development".trim(), id: 10 },
  { label: "Self-confidence & Empowerment".trim(), id: 11 },
];
export default category;
