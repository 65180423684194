import React from "react";
import Iam from "../../assets/images/Iam.png";
import Iam_large from "../../assets/images/Iam_large.png";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const Welcome = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "95vh",
      }}
    >
      {isSmallScreen ? (
        <img
          src={Iam}
          alt="affirmation-logo"
          style={{
            width: "50%",
            height: "auto",
          }}
        />
      ) : (
        <img
          src={Iam_large}
          alt="affirmation-logo"
          style={{
            width: "15%",
            height: "auto",
          }}
        />
      )}
      <Button
        onClick={() => navigate("/signin")}
        variant="text" //contained or text
        sx={{
          boxShadow: "0px -3px 3px inset grey",
          cursor: "pointer",
          color: "#777777",
          borderRadius: "10px",
          padding: "1rem",
          margin: "1.5rem",
          width: {
            xs: "40px",
            sm: "80px",
            md: "90px",
          },
          fontSize: {
            xs: "13px",
            sm: "13px",
            md: "14px",
          },
          transition: "0.2s", // Add transition for smoother hover effect
          "&:hover": {
            cursor: "pointer", // Change cursor to pointer on hover
            bgcolor: "#3c3c3c", // Change background color on hover
            color: "white",
            boxShadow: "0px 4px 6px inset black", // Change boxShadow on hover
          },
          "&:active": {
            bgcolor: "#3c3c3c", // Change background color on hover
            color: "white",
            boxShadow: "0px 4px 6px inset black", // Change boxShadow on click
          },
        }}
      >
        I AM
      </Button>
    </Box>
  );
};
export default Welcome;
