// useMainState.js
import { useState } from "react";

export const useMainState = () => {
  const [state, setState] = useState({
    selectedValue: "",
    apiLoadResponse: "",
    apiAffirmationResponse: "",
    isBtnClicked: false,
    clickCount: 0,
    isLoading: false,
    isReaffirmLoading: false,
    affirmationArrived: false,
    reaffirmClick: 0,
  });
  //main state-setter function
  const stateSetter = (key, value) => {
    setState((prev) => ({
      ...prev,
      [key]: typeof value === "function" ? value(prev[key]) : value,
    }));
  };

  //different state-setter/state-related functions
  const updateIsReaffirmLoading = (value) => {
    stateSetter("isReaffirmLoading", value);
  };
  const increaseCount = () => {
    stateSetter("clickCount", (prevCount) => prevCount + 1);
  };
  const increaseReaffirmCount = () => {
    stateSetter("reaffirmClick", (prevClick) => prevClick + 1);
  };
  const resetReaffirmCount = () => {
    stateSetter("reaffirmClick", 0);
  };
  const refreshAffirmationAffirmer = () => {
    stateSetter("affirmationArrived", false);
  };
  const refreshSelectedValue = () => {
    stateSetter("selectedValue", "");
  };
  // changes isBtnClicked into true
  const toggleDisplay = () => {
    stateSetter("isBtnClicked", (prevValue) => !prevValue);
  };
  const refreshResponse = () => {
    setState((prevState) => ({
      ...prevState,
      apiLoadResponse: "",
      apiAffirmationResponse: "",
    }));
  };
  const handleSubmit = () => {
    if (state.selectedValue) {
      increaseCount();
      stateSetter("isLoading", true);
      toggleDisplay(); //check this for how UI changes accordingly
    } else {
      alert("Please select a value before submitting.");
    }
  };
  //functions used in useEffect
  const postLoadResponseArrival = (fetchedLoadResponse) => {
    stateSetter("apiLoadResponse", fetchedLoadResponse);
    stateSetter("isLoading", false); //REMOVES BACKDROP Component from UI
  };
  const postAffirmationArrival = (fetchedResponse) => {
    stateSetter("apiAffirmationResponse", fetchedResponse);
    stateSetter("affirmationArrived", true);
  };
  const postReAffirmationArrival = (fetchedResponse) => {
    updateIsReaffirmLoading(false);
    postAffirmationArrival(fetchedResponse);
  };
  return {
    state,
    stateSetter,
    updateIsReaffirmLoading,
    increaseReaffirmCount,
    resetReaffirmCount,
    refreshAffirmationAffirmer,
    refreshSelectedValue,
    toggleDisplay,
    refreshResponse,
    handleSubmit,
    postLoadResponseArrival,
    postAffirmationArrival,
    postReAffirmationArrival,
  };
};
